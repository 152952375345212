<template>
  <v-dialog :value="value" persistent max-width="900px">
    <v-container fluid class="ma-0 pa-0">
      <v-card>
          <v-toolbar dense flat class="pl-4 pt-4">
            <v-icon class="mr-2 ml-0">mdi-file-upload-outline</v-icon>
            <v-toolbar-title class="title">
              Upload Contract
            </v-toolbar-title>
            <v-col cols="auto" class="ml-auto pr-1">
              <v-btn color="info" @click="downloadTemplate">
                <v-icon left>mdi-file-download-outline</v-icon>
                Template
              </v-btn>
            </v-col>
            <v-col cols="auto" class="pr-0">
              <v-btn 
                color="primary"
                :loading="loading" 
                :disabled="uploadDisabled"
                @click="uploadFile">
                <v-icon left>mdi-file-upload-outline</v-icon>
                {{ reupload ? "Reupload" : "Upload" }}
              </v-btn>
            </v-col>
            <v-col cols="auto" class="pr-0">
              <v-btn icon @click="$emit('closeUpload')">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-toolbar>
          <v-card-text class="py-4">
          <v-container fluid>
            <p class="pt-0 mt-0 pl-2 ml-2" style="color: grey; font-size: 16px">
              {{ reupload ? "Reupload the contract file." : "Create promotions via contract file." }}
            </p>
            <template>
              <v-form ref="form" lazy-validation v-model="valid">
                <v-row dense>
                  <v-col cols="8">
                    <v-file-input
                      v-model="file"
                      outlined 
                      dense
                      hide-details="auto" 
                      truncate-length="100"
                      label="File*"
                      clearable
                      accept=".xlsx"
                      @change="handleFile">
                    </v-file-input>
                  </v-col>
                  <v-col cols="3" class="pt-0" v-if="tenant === 'pricechopper'">
                    <v-checkbox
                      v-if="showMultiVendor"
                      v-model="payload.multi_vendor"
                      label="Private Label Contract"
                      color="primary"
                      class="mt-0 pt-0"
                      dense
                      hide-details
                      :ripple="false"
                      @change="multiVendorChange">
                    </v-checkbox>
                    <v-checkbox
                      v-model="payload.non_allowance"
                      label="Non-Allowance Contract"
                      color="primary"
                      dense
                      hide-details
                      :ripple="false">
                    </v-checkbox>
                  </v-col>
                  <template v-if="tenant === 'alliance-retail-group'">
                    <v-col 
                      v-for="obj in contractBoolsConfig"
                      :key="obj.value"
                      align-self="center">
                      <v-checkbox
                        v-model="payload[`${obj.value}`]"
                        :label="obj.label"
                        color="primary"
                        class="ma-auto"
                        dense
                        :disabled="obj.disabled"
                        hide-details
                        :ripple="false">
                      </v-checkbox>
                    </v-col>
                  </template>
                </v-row>
                
                <v-container fluid class="px-4">
                  <v-row dense class="mb-2" v-if="useVendorSelections">
                    <v-col cols="6">
                      <VendorSingleSelect
                        :disabled="payload.multi_vendor"
                        :value.sync="payload.vendor_party_id"/>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="payload.subvendor_party_id"
                        :disabled="subvendorDisabled"
                        :items="subvendors"
                        item-text="name"
                        item-value="id"
                        item-color="action"
                        label="Subvendor*"
                        :loading="loadingSubvendors"
                        :rules="[v => payload.vendor_party_id ? (!!v || 'Required field') : true]"
                        validate-on-blur
                        clearable
                        background-color="#fff"
                        dense
                        outlined
                        hide-details="auto">
                      </v-autocomplete>
                    </v-col>
                  </v-row>

                  <v-row dense class="pb-2">
                    <v-col v-if="useCategoryManager" cols="6">
                      <v-autocomplete
                        v-model="payload.category_manager_id"
                        :items="categoryManagers"
                        item-text="name"
                        item-value="id"
                        item-color="action"
                        label="Category Manager*"
                        clearable
                        :rules="[v => !!v || 'Required field']"
                        validate-on-blur
                        background-color="#fff"
                        dense
                        outlined
                        hide-details="auto">
                      </v-autocomplete>
                    </v-col>
                    <v-col v-if="tenant === 'alliance-retail-group'" cols="6">
                      <ContractAdGroups 
                        :value.sync="payload.ad_group_party_ids">
                      </ContractAdGroups>
                    </v-col>
                  </v-row>

                  <template v-if="tenant === 'alliance-retail-group'">
                    <v-divider class="my-2"></v-divider>
                      <v-row>
                        <v-col cols="4">
                        <v-text-field 
                          v-model="payload.lump_sum_allowance"
                          label="Ad Fee"
                          type="number"
                          min="0.00"
                          step=".01"
                          prefix="$"
                          outlined
                          dense
                          hide-details="auto">
                        </v-text-field>
                      </v-col>
                        <v-col cols="4">
                          <v-autocomplete
                            v-model="payload.ad_billback_category_id"
                            :items="adBillbackPromoCategories"
                            item-text="name"
                            item-value="id"
                            item-color="action"
                            label="Ad Billback Category"
                            clearable
                            background-color="#fff"
                            dense
                            outlined
                            hide-details="auto"
                            @click:clear="resetSpecialAdLength"
                            @change="resetSpecialAdLength">
                          </v-autocomplete>
                        </v-col>
                      <v-col cols="4" align-self="center">
                        <v-checkbox
                          v-model="specialAdLength"
                          label="Special Ad Length"
                          color="primary"
                          class="my-auto"
                          dense
                          :disabled="!payload.ad_billback_category_id || (['Ad Billback - 1-2 Day Ad', 'Ad Billback Non-Allowance'].includes(selectedCategoryName))"
                          hide-details
                          :ripple="false">
                        </v-checkbox>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col align-self="center">
                        <v-text-field 
                          v-model="payload.digital_coupon_note"
                          :disabled="!payload.digital_coupon"
                          label="Digital Coupon Note"
                          outlined
                          dense
                          hide-details="auto">
                        </v-text-field>
                      </v-col>
                      <v-col cols="4" align-self="center">
                        <v-checkbox
                          v-model="payload.digital_coupon"
                          label="Digital Coupon"
                          color="primary"
                          class="my-auto"
                          dense
                          hide-details
                          :ripple="false">
                        </v-checkbox>
                      </v-col>
                    </v-row>

                    <v-divider class="my-2"></v-divider>

                    <v-row dense class="pt-2">
                      <v-col 
                        v-for="config in contractDatesConfig"
                        :cols="config.cols || 6"
                        :key="config.value"
                        class="py-2">
                        <ContractDates
                          :ref="`${config.value}_dates`"
                          :value.sync="payload[`${config.value}`]"
                          :config="config"
                          :specialAdLength="specialAdLength"
                          :ad_billback_category_id="payload.ad_billback_category_id"
                          hide-details="auto"
                          :error="showDateError">
                        </ContractDates>
                      </v-col>
                    </v-row>
                  </template>
                </v-container>
              </v-form>
            </template>
          </v-container>
        </v-card-text>
      </v-card>
    </v-container>
    <UploadDialog v-if="dialog" v-model="dialog" :file="file" @cancel="closeUploadDialog"
      @confirmUpload="confirmUpload">
    </UploadDialog>
  </v-dialog>
</template>
<script>
// api
import ContractUpload from '@/axios/contract-upload-endpoint.js'
import PartyRel from '@/axios/party-relationship-endpoint.js'
// components
import UploadDialog from '@/components/contracts/UploadDialog.vue'
import VendorSingleSelect from '@/components/VendorSingleSelect'
import ContractDates from '@/components/contracts/ContractDates.vue'
import ContractAdGroups from '@/components/contracts/ContractAdGroups.vue'
// mixins
import { displayAlert } from '@/mixins/alert'
import { userAccess } from '@/mixins/user-access'
import { utils } from '@/mixins/utils'
export default {
  name: 'ContractUploadModal',
  data() {
    return {
      vendors: [],
      subvendors: [],
      loadingSubvendors: false,
      file: null,
      newFile: false,
      valid: true,
      loading: false,
      dialog: false,
      specialAdLength: false,
      showDateError: false,
      payload: {
        vendor_party_id: null,
        subvendor_party_id: null,
        category_manager_id: null,
        ad_group_party_ids: [],
        multi_vendor: false,
        non_allowance: false,
        dsd: false,
        perishable: false,
        ad_billback_category_id: null,
        lump_sum_allowance: null,
        tpr: { start_dt: null, end_dt:  null },
        edlc: { start_dt: null, end_dt:  null },
        amap: { start_dt: null, end_dt:  null },
        eba: { start_dt: null, end_dt:  null },
        ad_1: { start_dt: null, end_dt:  null },
        ad_2: { start_dt: null, end_dt:  null },
        ad_3: { start_dt: null, end_dt:  null },
        digital_coupon: false,
        digital_coupon_note: null
      }
    }
  },
  mixins: [displayAlert, userAccess, utils],
  components: { UploadDialog, VendorSingleSelect, ContractDates, ContractAdGroups },
  props: {
    value: Boolean,
    reupload: {
      type: Boolean,
      default: false
    },
    uploadId: {
      type: String,
      default: ""
    }
  },
  watch: {
    'payload.vendor_party_id': {
      async handler(newValue) {
        if (newValue) {
          await this.updateSubvendors()
        }
      }
    },
    'payload.digital_coupon': {
      handler(newValue) {
        if (newValue === false) {
          this.payload.digital_coupon_note = null
        }
      }
    },
    noPromoDatesSelected: {
      handler(newValue) {
        if (newValue === false) {
          this.showDateError = false
        }
      }
    }
  },
  async created () {
    // TO DO: auto-populate payload fields if re-uploading a contract
    if (this.promo_categories.length === 0) {
      await this.$store.dispatch('getPromoCategories')
    }
  },
  computed: {
    subvendorDisabled () {
      return !this.payload.vendor_party_id || this.payload.multi_vendor
    },
    useVendorSelections () {
      return ['alliance-retail-group', 'pricechopper'].includes(this.tenant) && !this.payload.multi_vendor
    },
    useCategoryManager () {
      return ['alliance-retail-group', 'pricechopper'].includes(this.tenant)
    },
    categoryManagers () {
      return this.$store.getters.categoryManagers
    },
    showMultiVendor () {
      return this.tenant === 'pricechopper' && (this.isAdmin || this.userPermissions.includes('contract:multi_vendor'))
    },
    adBillbackPromoCategories () {
      const categories = [
        'Ad Billback - 1-2 Day Ad', 'Ad Billback - 1 Week Ad', 'Ad Billback - 2 Week Ad', 
        'Ad Billback Non-Allowance', 'Ad Billback - PCE Meat', 'Ad Billback - Non Standard'
      ]
      return categories.flatMap(catName => {
        return this.userPromoCategories.find(cat => catName === cat?.name) || []
      })
    },
    selectedCategoryName() {
      if (this.payload.ad_billback_category_id) {
        const category = this.userPromoCategories.find(c => c?.id === this.payload.ad_billback_category_id)
        return category?.name
      }
      return null
    },
    nonAllowanceSelected() {
      return this.selectedCategoryName?.toLowerCase()?.includes('non-allowance') || false
    },
    uploadDisabled () {
      return this.loading || !this.file 
          || (this.useVendorSelections && (!this.payload.vendor_party_id || !this.payload.subvendor_party_id))
          || (this.useCategoryManager && !this.payload.category_manager_id)
          || (this.tenant === 'alliance-retail-group' && this.payload.ad_group_party_ids.length === 0)
    },
    contractDatesConfig() {
      return [
        { label: 'Ad 1 Start/End', value: 'ad_1', billing_mode: 'AD', cols: 4 },
        { 
          label: 'Ad 2 Start/End', value: 'ad_2', billing_mode: 'AD', cols: 4, 
          disabled: !(this.payload.ad_1.start_dt && this.payload.ad_1.end_dt),
          min: this.payload.ad_1.end_dt
        },
        { 
          label: 'Ad 3 Start/End', value: 'ad_3', billing_mode: 'AD', cols: 4,
          disabled: !(this.payload.ad_2.start_dt && this.payload.ad_2.end_dt),
          min: this.payload.ad_2.end_dt
        },
        { label: 'TPR Start/End', value: 'tpr', billing_mode: 'TPR' },
        { label: 'EDLC Start/End', value: 'edlc', billing_mode: 'EDLC' },
        { label: 'AMAP Start/End', value: 'amap', billing_mode: 'AMAP', off_invoice: true },
        { label: 'EBA Start/End', value: 'eba', billing_mode: 'EBA', off_invoice: true }
      ]
    },
    contractBoolsConfig () {
      return [
        { label: 'Perishable', value: 'perishable', disabled: this.payload.dsd === true },
        { label: 'DSD', value: 'dsd', disabled: this.payload.perishable === true }
      ]
    },
    noPromoDatesSelected () {
      return !this.contractDatesConfig.some(config => {
        return (!config.off_invoice) 
          && this.payload[config.value].start_dt && this.payload[config.value].end_dt
      })
    },
    adDatesSelected() {
      return this.contractDatesConfig.some(config => {
        return (config.value.includes('ad')) 
          && this.payload[config.value].start_dt && this.payload[config.value].end_dt
      })
    }
  },
  methods: {
    handleFile(file) {
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.file.upload_file = e.target.result
        }
        reader.readAsArrayBuffer(file)
      }
    },
    reset() {
      this.file = null
    },
    uploadFile() {
      if (this.tenant !== 'alliance-retail-group') {
        return this.dialog = true
      }
      // TO DO: check full deal length
      if (this.noPromoDatesSelected) {
        this.showDateError = true
        return this.emitAlert(true, 'warning', 'No promo start/end dates selected')
      }
      if (this.payload.ad_billback_category_id && !this.adDatesSelected) {
        return this.emitAlert(true, 'warning', 'Ad billback category selected but no ad start/end dates')
      }
      let validDates = false
      for (const config of this.contractDatesConfig) {
        const field = this.$refs[`${config.value}_dates`][0]
        if (this.payload[config.value].start_dt && this.payload[config.value].end_dt) {
          validDates = field.validate()
        }
      }
      const validFields = validDates && this.$refs.form.validate()
      if (!validFields) {
        return this.emitAlert(true, 'warning', 'Please check your form for errors')
      }
      this.dialog = true
    },
    closeUploadDialog() {
      this.dialog = false
    },
    confirmUpload() {
      this.closeUploadDialog()
      this.upload()
    },
    multiVendorChange(newValue) {
      if (newValue) {
        this.payload.vendor_party_id = null
        this.payload.subvendor_party_id = null
      }
    },
    resetSpecialAdLength() {
      this.specialAdLength = false
    },
    async upload() {
      try {
        await this.processFile(this.file)
        this.emitAlert(true, 'success', 'File(s) have been successfully uploaded and are now being processed.')
        this.$emit('closeUpload', true)
        this.reset()
      } catch (err) {
        this.handleError(err)
      }
    },
    async processFile(file) {
      const blob = new Blob([file.upload_file], { type: `${file.type}` })
      const formData = new FormData()
      const pathEnv = `${process.env.VUE_APP_FILE_PATH}`
      const today = (new Date()).toISOString().split('T')[0]

      formData.append('path', `${pathEnv}/${this.tenant}/uploads/${today}/`)
      formData.append('name', `${file.name}`)
      formData.append('upload_file', blob, `${file.name}`)

      if (this.tenant === 'alliance-retail-group') {
        this.payload.non_allowance = this.nonAllowanceSelected
      }
      formData.append('payload', JSON.stringify(this.payload))

      if (this.reupload) {
        return ContractUpload.rerunContractUpload(formData, this.uploadId)
      } else {
        return ContractUpload.uploadContract(formData)
      }
    },
    async downloadTemplate() {
      try {
        this.emitAlert(true, 'info', "Template downloading")
        const response = await ContractUpload.downloadTemplate()
        const url = response.data.url
        window.open(url, '_blank')
      } catch (err) {
        console.error(err)
        this.emitAlert(true, 'error', "Error while downloading template")
      }
    },
    async updateSubvendors() {
      this.loadingSubvendors = true
      try {
        this.payload.subvendor_party_id = null
        this.subvendors = []
        const subvendorParties = await this.getDownstreamSubvendorParties(this.payload.vendor_party_id)
        if (subvendorParties.length > 0) {
          this.subvendors = subvendorParties.sort((a, b) => a.name.localeCompare(b.name))
        }
      } catch (err) {
        this.handleError(err)
      }
      this.loadingSubvendors = false
    },
    async getDownstreamSubvendorParties (vendor_id) {
      const downstreamSubvendorRes = await PartyRel.downstreamRelationshipSearch({ to_party_ids: [vendor_id] })
      if (downstreamSubvendorRes.data?.length !== 0) {
        const downstreamSubvendors = downstreamSubvendorRes.data
          .filter(rel => rel.from_rel_type === 'CHILD_VENDOR_FOR')
          .map(rel => {
            let subvendor = {}
            subvendor.name = rel.from_party_name
            subvendor.party_name = `${subvendor.name}`
            subvendor.id = rel.from_party_id
            return subvendor
          })
        return downstreamSubvendors
      } else {
        return []
      }
    },
  }
}
</script>