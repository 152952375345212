var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.value, persistent: "", "max-width": "900px" } },
    [
      _c(
        "v-container",
        { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { staticClass: "pl-4 pt-4", attrs: { dense: "", flat: "" } },
                [
                  _c("v-icon", { staticClass: "mr-2 ml-0" }, [
                    _vm._v("mdi-file-upload-outline")
                  ]),
                  _c("v-toolbar-title", { staticClass: "title" }, [
                    _vm._v(" Upload Contract ")
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "ml-auto pr-1", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "info" },
                          on: { click: _vm.downloadTemplate }
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-file-download-outline")
                          ]),
                          _vm._v(" Template ")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pr-0", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            loading: _vm.loading,
                            disabled: _vm.uploadDisabled
                          },
                          on: { click: _vm.uploadFile }
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-file-upload-outline")
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(_vm.reupload ? "Reupload" : "Upload") +
                              " "
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pr-0", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("closeUpload")
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "py-4" },
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "pt-0 mt-0 pl-2 ml-2",
                          staticStyle: { color: "grey", "font-size": "16px" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.reupload
                                  ? "Reupload the contract file."
                                  : "Create promotions via contract file."
                              ) +
                              " "
                          )
                        ]
                      ),
                      [
                        _c(
                          "v-form",
                          {
                            ref: "form",
                            attrs: { "lazy-validation": "" },
                            model: {
                              value: _vm.valid,
                              callback: function($$v) {
                                _vm.valid = $$v
                              },
                              expression: "valid"
                            }
                          },
                          [
                            _c(
                              "v-row",
                              { attrs: { dense: "" } },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "8" } },
                                  [
                                    _c("v-file-input", {
                                      attrs: {
                                        outlined: "",
                                        dense: "",
                                        "hide-details": "auto",
                                        "truncate-length": "100",
                                        label: "File*",
                                        clearable: "",
                                        accept: ".xlsx"
                                      },
                                      on: { change: _vm.handleFile },
                                      model: {
                                        value: _vm.file,
                                        callback: function($$v) {
                                          _vm.file = $$v
                                        },
                                        expression: "file"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm.tenant === "pricechopper"
                                  ? _c(
                                      "v-col",
                                      {
                                        staticClass: "pt-0",
                                        attrs: { cols: "3" }
                                      },
                                      [
                                        _vm.showMultiVendor
                                          ? _c("v-checkbox", {
                                              staticClass: "mt-0 pt-0",
                                              attrs: {
                                                label: "Private Label Contract",
                                                color: "primary",
                                                dense: "",
                                                "hide-details": "",
                                                ripple: false
                                              },
                                              on: {
                                                change: _vm.multiVendorChange
                                              },
                                              model: {
                                                value: _vm.payload.multi_vendor,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.payload,
                                                    "multi_vendor",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "payload.multi_vendor"
                                              }
                                            })
                                          : _vm._e(),
                                        _c("v-checkbox", {
                                          attrs: {
                                            label: "Non-Allowance Contract",
                                            color: "primary",
                                            dense: "",
                                            "hide-details": "",
                                            ripple: false
                                          },
                                          model: {
                                            value: _vm.payload.non_allowance,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.payload,
                                                "non_allowance",
                                                $$v
                                              )
                                            },
                                            expression: "payload.non_allowance"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.tenant === "alliance-retail-group"
                                  ? _vm._l(_vm.contractBoolsConfig, function(
                                      obj
                                    ) {
                                      return _c(
                                        "v-col",
                                        {
                                          key: obj.value,
                                          attrs: { "align-self": "center" }
                                        },
                                        [
                                          _c("v-checkbox", {
                                            staticClass: "ma-auto",
                                            attrs: {
                                              label: obj.label,
                                              color: "primary",
                                              dense: "",
                                              disabled: obj.disabled,
                                              "hide-details": "",
                                              ripple: false
                                            },
                                            model: {
                                              value:
                                                _vm.payload["" + obj.value],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.payload,
                                                  "" + obj.value,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "payload[`${obj.value}`]"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    })
                                  : _vm._e()
                              ],
                              2
                            ),
                            _c(
                              "v-container",
                              { staticClass: "px-4", attrs: { fluid: "" } },
                              [
                                _vm.useVendorSelections
                                  ? _c(
                                      "v-row",
                                      {
                                        staticClass: "mb-2",
                                        attrs: { dense: "" }
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c("VendorSingleSelect", {
                                              attrs: {
                                                disabled:
                                                  _vm.payload.multi_vendor,
                                                value:
                                                  _vm.payload.vendor_party_id
                                              },
                                              on: {
                                                "update:value": function(
                                                  $event
                                                ) {
                                                  return _vm.$set(
                                                    _vm.payload,
                                                    "vendor_party_id",
                                                    $event
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c("v-autocomplete", {
                                              attrs: {
                                                disabled: _vm.subvendorDisabled,
                                                items: _vm.subvendors,
                                                "item-text": "name",
                                                "item-value": "id",
                                                "item-color": "action",
                                                label: "Subvendor*",
                                                loading: _vm.loadingSubvendors,
                                                rules: [
                                                  function(v) {
                                                    return _vm.payload
                                                      .vendor_party_id
                                                      ? !!v || "Required field"
                                                      : true
                                                  }
                                                ],
                                                "validate-on-blur": "",
                                                clearable: "",
                                                "background-color": "#fff",
                                                dense: "",
                                                outlined: "",
                                                "hide-details": "auto"
                                              },
                                              model: {
                                                value:
                                                  _vm.payload
                                                    .subvendor_party_id,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.payload,
                                                    "subvendor_party_id",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "payload.subvendor_party_id"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-row",
                                  { staticClass: "pb-2", attrs: { dense: "" } },
                                  [
                                    _vm.useCategoryManager
                                      ? _c(
                                          "v-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c("v-autocomplete", {
                                              attrs: {
                                                items: _vm.categoryManagers,
                                                "item-text": "name",
                                                "item-value": "id",
                                                "item-color": "action",
                                                label: "Category Manager*",
                                                clearable: "",
                                                rules: [
                                                  function(v) {
                                                    return (
                                                      !!v || "Required field"
                                                    )
                                                  }
                                                ],
                                                "validate-on-blur": "",
                                                "background-color": "#fff",
                                                dense: "",
                                                outlined: "",
                                                "hide-details": "auto"
                                              },
                                              model: {
                                                value:
                                                  _vm.payload
                                                    .category_manager_id,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.payload,
                                                    "category_manager_id",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "payload.category_manager_id"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.tenant === "alliance-retail-group"
                                      ? _c(
                                          "v-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c("ContractAdGroups", {
                                              attrs: {
                                                value:
                                                  _vm.payload.ad_group_party_ids
                                              },
                                              on: {
                                                "update:value": function(
                                                  $event
                                                ) {
                                                  return _vm.$set(
                                                    _vm.payload,
                                                    "ad_group_party_ids",
                                                    $event
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _vm.tenant === "alliance-retail-group"
                                  ? [
                                      _c("v-divider", { staticClass: "my-2" }),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "4" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "Ad Fee",
                                                  type: "number",
                                                  min: "0.00",
                                                  step: ".01",
                                                  prefix: "$",
                                                  outlined: "",
                                                  dense: "",
                                                  "hide-details": "auto"
                                                },
                                                model: {
                                                  value:
                                                    _vm.payload
                                                      .lump_sum_allowance,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.payload,
                                                      "lump_sum_allowance",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "payload.lump_sum_allowance"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "4" } },
                                            [
                                              _c("v-autocomplete", {
                                                attrs: {
                                                  items:
                                                    _vm.adBillbackPromoCategories,
                                                  "item-text": "name",
                                                  "item-value": "id",
                                                  "item-color": "action",
                                                  label: "Ad Billback Category",
                                                  clearable: "",
                                                  "background-color": "#fff",
                                                  dense: "",
                                                  outlined: "",
                                                  "hide-details": "auto"
                                                },
                                                on: {
                                                  "click:clear":
                                                    _vm.resetSpecialAdLength,
                                                  change:
                                                    _vm.resetSpecialAdLength
                                                },
                                                model: {
                                                  value:
                                                    _vm.payload
                                                      .ad_billback_category_id,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.payload,
                                                      "ad_billback_category_id",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "payload.ad_billback_category_id"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "4",
                                                "align-self": "center"
                                              }
                                            },
                                            [
                                              _c("v-checkbox", {
                                                staticClass: "my-auto",
                                                attrs: {
                                                  label: "Special Ad Length",
                                                  color: "primary",
                                                  dense: "",
                                                  disabled:
                                                    !_vm.payload
                                                      .ad_billback_category_id ||
                                                    [
                                                      "Ad Billback - 1-2 Day Ad",
                                                      "Ad Billback Non-Allowance"
                                                    ].includes(
                                                      _vm.selectedCategoryName
                                                    ),
                                                  "hide-details": "",
                                                  ripple: false
                                                },
                                                model: {
                                                  value: _vm.specialAdLength,
                                                  callback: function($$v) {
                                                    _vm.specialAdLength = $$v
                                                  },
                                                  expression: "specialAdLength"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              attrs: { "align-self": "center" }
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  disabled: !_vm.payload
                                                    .digital_coupon,
                                                  label: "Digital Coupon Note",
                                                  outlined: "",
                                                  dense: "",
                                                  "hide-details": "auto"
                                                },
                                                model: {
                                                  value:
                                                    _vm.payload
                                                      .digital_coupon_note,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.payload,
                                                      "digital_coupon_note",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "payload.digital_coupon_note"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "4",
                                                "align-self": "center"
                                              }
                                            },
                                            [
                                              _c("v-checkbox", {
                                                staticClass: "my-auto",
                                                attrs: {
                                                  label: "Digital Coupon",
                                                  color: "primary",
                                                  dense: "",
                                                  "hide-details": "",
                                                  ripple: false
                                                },
                                                model: {
                                                  value:
                                                    _vm.payload.digital_coupon,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.payload,
                                                      "digital_coupon",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "payload.digital_coupon"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-divider", { staticClass: "my-2" }),
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "pt-2",
                                          attrs: { dense: "" }
                                        },
                                        _vm._l(
                                          _vm.contractDatesConfig,
                                          function(config) {
                                            return _c(
                                              "v-col",
                                              {
                                                key: config.value,
                                                staticClass: "py-2",
                                                attrs: {
                                                  cols: config.cols || 6
                                                }
                                              },
                                              [
                                                _c("ContractDates", {
                                                  ref: config.value + "_dates",
                                                  refInFor: true,
                                                  attrs: {
                                                    value:
                                                      _vm.payload[
                                                        "" + config.value
                                                      ],
                                                    config: config,
                                                    specialAdLength:
                                                      _vm.specialAdLength,
                                                    ad_billback_category_id:
                                                      _vm.payload
                                                        .ad_billback_category_id,
                                                    "hide-details": "auto",
                                                    error: _vm.showDateError
                                                  },
                                                  on: {
                                                    "update:value": function(
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        _vm.payload,
                                                        "" + config.value,
                                                        $event
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ]
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.dialog
        ? _c("UploadDialog", {
            attrs: { file: _vm.file },
            on: {
              cancel: _vm.closeUploadDialog,
              confirmUpload: _vm.confirmUpload
            },
            model: {
              value: _vm.dialog,
              callback: function($$v) {
                _vm.dialog = $$v
              },
              expression: "dialog"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }