<template>
  <v-autocomplete
    v-model="selectedAdGroupIds"
    :items="activeAdGroups"
    item-text="name"
    item-value="id"
    item-color="action"
    label="Ad Groups*"
    clearable
    chips
    small-chips
    background-color="#fff"
    :rules="[v => (Array.isArray(v) && v.length > 0) || 'Required field']"
    validate-on-blur
    dense
    multiple
    outlined
    hide-details="auto">
    <template v-slot:prepend-item>
      <v-list-item>
        <v-list-item-action>
          <v-checkbox v-model="allAdGroupsSelected">
            <template v-slot:label>
              <span class="text-subtitle-2 ml-8 text-black font-weight-bold text--black">
                Select All
              </span>
            </template>
          </v-checkbox>
        </v-list-item-action>
      </v-list-item>
      <v-divider/>
    </template>
    <template v-slot:item="{ item, attrs: { inputValue, disabled, ripple } }">
      <div style="width: 100%">
        <template>
          <v-list-item class="ma-0 pa-0" :disabled="disabled">
            <v-list-item-action>
              <v-checkbox color="action" :input-value="inputValue" :disabled="disabled" :ripple="ripple"/>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template> 
      </div>
    </template>
    <template v-slot:selection="{ item, index }">
      <div>
        <v-chip v-if="allAdGroupsSelected && index === 1"
          small
          @click.stop="">
          <span> All Groups Selected </span>
        </v-chip>
        <v-chip v-else-if="index < 3 && !allAdGroupsSelected"
          small
          @click.stop="">
          <span> {{ item.name }} </span>
        </v-chip>
        <v-chip v-else-if="index === 3 && !allAdGroupsSelected"
          small
          @click.stop="">
          <span> {{ `+${selectedAdGroupIds.length - 3}` }} </span>
        </v-chip>
      </div>
    </template>
  </v-autocomplete>
</template>
<script>
import { userAccess } from '@/mixins/user-access'
import { utils } from '@/mixins/utils'
export default {
  data() {
    return {
      selectedAdGroupIds: [],
      allAdGroupsSelected: false
    }
  },
  name: 'ContractAdGroups',
  mixins: [userAccess, utils],
  props: {
    value: Array
  },
  watch: {
    selectedAdGroupIds: {
      handler(newValue, oldValue) {
        if (this.allAdGroupsSelected && newValue.length < oldValue.length) {
          this.allAdGroupsSelected = false
        }
        this.$emit('update:value', this.selectedAdGroupIds)
      },
      deep: true
    },
    allAdGroupsSelected(newValue) {
      if (newValue) {
        this.selectedAdGroupIds = this.activeAdGroups.map(group => group.id)
      } else if (!newValue && this.selectedAdGroupIds.length === this.activeAdGroups.length) {
        this.selectedAdGroupIds = []
      }
    }
  },
  computed: {
    activeAdGroups () {
      let groups = this.$store.getters.adGroups.filter((g) => g?.attributes?.AD_GROUP_IS_ACTIVE === 'True')
      if (this.limitAccessByRelatedStores) {
        groups = groups.filter(g => this.userRelatedPartyIds.includes(g.id))
      }
      return this.sortByKey(groups, 'name')
    }
  }
}
</script>
